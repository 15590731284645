var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-iam", attrs: { fluid: "", "pa-0": "" } },
    [
      _c("inn-breadcrumbs", { attrs: { items: _setup.breadcrumbs } }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("header", { staticClass: "app-welcome-message" }, [
            _c(
              "h2",
              [
                _c("v-icon", [_vm._v(_vm._s(_setup.mdiScriptTextKeyOutline))]),
                _vm._v(" Recht "),
              ],
              1
            ),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "sidebar", attrs: { cols: "2" } },
                [
                  _c(
                    "v-list",
                    { attrs: { nav: "" } },
                    [
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-PermissionDetails" },
                                disabled: _vm.addModus,
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("Details")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-PermissionRoles" },
                                disabled: _vm.addModus,
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("Rollen")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: {
                                text: "",
                                to: { name: "IAM-PermissionCategories" },
                                disabled: _vm.addModus,
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("Categorieën")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("router-view", {
                    on: { "update-permission": _setup.updateBreadCrumbName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }